<template>
  <div>
    relatorio
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>

<!-- 11_null master -->
